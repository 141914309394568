@import '../../styles/common.scss';

.sectionContainer {
  position: relative;
  font-family: 'Roboto', sans-serif;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}

.flareOne {
  position: absolute;
  width: 39.61px;
  height: 482.57px;
  left: 50px;
  top: 200px;
  background: #EDECFF;
  filter: blur(50px);
  transform: rotate(-36.38deg);
}

.flareTwo {
  position: absolute;
  width: 70.28px;
  height: 261.6px;
  right: 14px;
  top: 1000px;
  background: #EDECFF;
  filter: blur(50px);
  transform: matrix(-0.84, -0.54, -0.54, 0.84, 0, 0);
}

.flareThree {
  position: absolute;
  width: 308px;
  height: 155px;
  left: -93px;
  top: 1800px;
  background: rgba(237, 236, 255, 0.3);
  filter: blur(50px);
}

.flareLgOne {
  position: absolute;
  display: none;
  width: 57.53px;
  height: 700.92px;
  left: -132px;
  top: 135px;
  background: #EDECFF;
  filter: blur(50px);
  transform: rotate(-36.38deg);
}

.flareLgTwo {
  position: absolute;
  display: none;
  width: 108px;
  height: 402px;
  left: 1100px;
  top: 840px;
  background: #EDECFF;
  filter: blur(50px);
  transform: matrix(-0.84, -0.54, -0.54, 0.84, 0, 0);
}

.flareLgThree {
  position: absolute;
  display: none;
  width: 308px;
  height: 155px;
  left: -41px;
  top: 1220px;
  background: #EDECFF;
  filter: blur(50px);
}

.sectionHeading {
  width: 100%;
  color: $primary-3;
}

.sectionHeading > h1 {
  font-size: 40px;
  line-height: 52px;
  text-transform: uppercase;
  padding: 33px 0;
  border-bottom: 1px solid $border-color;
}

@media (min-width: 768px) {
  .flareLgThree,
  .flareLgTwo,
  .flareLgOne {
    display: block;
  }

  .flareOne,
  .flareTwo,
  .flareThree {
    display: none;
  }
}