@import '../../styles/common.scss';

.socialBar {
  display: flex;
  align-items: center;
}

.socialBarItem {
  padding: 0 0.5em;
  transition: all 0.5s ease;
}

.socialBarItem > a > svg {
  color: #fff;
  transition: all 0.5s ease;
}

.socialBarItem > a > svg:hover {
  color: darken(#fff, 40%);
}