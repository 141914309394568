@import "../../styles/common.scss";

.skillsList {

}

.skillsList > h4 {
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 500;
  color: $primary-2;
}

.list {
  padding-inline-start: 0;
  list-style-type: none;
  margin-left: none;
  padding-left: none;
}

.list > * {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
}
