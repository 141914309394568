@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import '~bootstrap/scss/bootstrap.scss';

$primary: #edecff;
$primary-2: #7d73fc;
$primary-3: #9490ff;
$primary-4: #edecff;
$secondary: #acb7c3;
$main-bg: #191765;
$paragraph: #c8c8d4;
$neutral-6: #a2a3ae;
$neutral-10: #F5F5FF;
$border-color: #646369;
