.footerContainer {
  border-top: 1px solid #3A3837;
  padding: 25px 0;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emailBrand {
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  color: #ebebff;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin-top: 10px;
  transition: all 0.5s ease;
}

.emailBrand:hover {
  text-decoration: none;
}