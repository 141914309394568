@import '../../styles/common.scss';

.itemContainer {
  z-index: 2;
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
}

.itemPicture {
  box-shadow: 3px 3px 3px 2px rgba(0, 0, 0, 0.2);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 236px;
  border-radius: 10px;
  width: 100%;
}

.pictureContainer {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoContainer {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.infoTitle {
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  color: $primary;
}

.infoSummary {
  margin-top: 40px;
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  color: $neutral-6;
}

.modalTitle {
  color: #fff;
  font-size: 20px;
  line-height: 28px;
}

.modalContentWrapper {
  background: transparent;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.modalRowOne {
  display: flex;
  height: 58px;
  align-items: center;
  justify-content: space-between;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}

.modalButtonsContainer {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalDescriptionParagraph {
  margin-top: 24px;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

.modalImgContainer {
  margin-top: 24px;
  max-height: 40vh;
  height: 181px;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
}

.closeButton {
  position: relative;
  top: -20px;
  background: transparent;
  border: none;
  color: #fff;
  padding: none;
  height: 50%;
}

@media (min-width: 768px) {
  .itemContainer {
    flex-direction: row;
  }

  .itemPicture,
  .pictureContainer {
    height: 448px;
  }

  .modalImgContainer {
    height: 505px;
    background-position: center;
    background-size: contain;
  }
}