@import '../../styles/common.scss';

.navbarItem {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  margin-top: 16px;
  height: 40px;
  padding: 0 40px;
  color: $primary;
}

.navbarItem:hover {
  cursor: pointer;
}

.icon {
  color: $secondary;
}
