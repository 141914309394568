@import '../../styles/common.scss';

.scrollBrand {
  cursor: pointer;
  margin-top: 40px;
  display: flex;
  color: $neutral-6;
  align-items: center;
}

.scrollBrand svg {
  color: $neutral-6;
}

.scrollBrand > span {
  font-size: 20px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .scrollBrand {
    margin-top: 0;
  }
}