#root {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  background: #191765;
}

.blurred {
  filter: blur(8px);
}
