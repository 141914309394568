@import './styles/common.scss';

.navBarToggle {
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navBrand {
  cursor: pointer;
  align-self: flex-end;
  font-size: 18px;
  letter-spacing: 0.03em;
}

.mainContainer {
  max-width: 1440px;
  overflow: hidden;
  margin: 0 auto;
}

@media (min-width: 720px) {
  .navBarToggle {
    display: none;
  }

  .mainContainer {
    overflow: visible;
  }
}