@import "../../styles/common.scss";

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skillsRow {
  padding-top: 40px;
}

.headerCentered {
  width: 327px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid $border-color;
  margin-bottom: 55px;
}

.name {
  color: $primary-2;
  font-size: 48px;
  padding-top: 63px;
}

.title {
  color: $primary-4;
  font-size: 32px;
  padding: 22px 0;
}

.paragraph {
  padding: 40px 0 33px 0;
  font-size: 20px;
  line-height: 28px;
  color: $paragraph;
  text-align: center;
  font-weight: 400;
  border-bottom: 1px solid #646369;
}

.figMobileOne {
  position: absolute;
  background: #edecff;
  filter: blur(50px);
  width: 160px;
  height: 160px;
  left: -106px;
  top: 0px;
}

.figMobileTwo {
  position: absolute;
  width: 68px;
  height: 68px;
  left: 339px;
  top: 495px;
  background: #edecff;
  filter: blur(50px);
}

.headingFooter {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.figLgOne {
  position: absolute;
  display: none;
  width: 184px;
  height: 184px;
  left: 120px;
  top: 0;
  background: #EDECFF;
  filter: blur(50px);
}

.figLgTwo {
  position: absolute;
  width: 226px;
  height: 226px;
  left: 780px;
  top: 345px;
  background: #EDECFF;
  filter: blur(50px);
}

.headingFooter > div:first-child {
  display: none;
}

@media (min-width: 768px) {
  .figMobileOne,
  .figMobileTwo {
    display: none;
  }

  .figLgTwo,
  .figLgOne {
    display: block;
  }

  .headerCentered {
    width: 448px;
  }

  .figMobileTwo {
    display: none;
  }

  .headingFooter {
    flex-direction: row;
  }

  .headingFooter > div:first-child {
    display: block;
  }
}
